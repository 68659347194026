/* eslint-disable no-undef */
import * as Sentry from "@sentry/react";
import { useEffect } from "react";
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from "react-router-dom";

const ignorableErrors = ["ReferenceError: Can't find variable: gmo"];

Sentry.init({
  dsn: "https://aae6b81a94236e9d5eef8215e87c60cc@o4508247628775424.ingest.us.sentry.io/4508247631069184",
  environment: process.env.REACT_APP_ENVNAME,
  release: `obx-client@${process.env.REACT_APP_VERSION_NO}`,
  ignoreErrors: ignorableErrors,
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes
    }),
    Sentry.replayIntegration()
  ],

  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions

  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    "localhost",
    /^https:\/\/obx-dev.sonobelloapps.com\/api/,
    /^https:\/\/obx-staging.sonobelloapps.com\/api/,
    /^https:\/\/obx-rel.sonobelloapps.com\/api/,
    /^https:\/\/booking.sonobello.com\/api/
  ],

  // Session Replay
  // This sets the sample rate at 5% for non-error sessions.
  replaysSessionSampleRate: process.env.REACT_APP_ENVNAME !== "Production" ? 0 : 0.05,
  // Sample at 100% for all sessions experiencing errors.
  replaysOnErrorSampleRate: 1.0
});
